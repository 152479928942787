<template>
    <div class="content">
        <div class="tabbarBox">
            <div class="titleBox">
                <div class="title">课程中心</div>
            </div>
            <div class="box-classifyBox">
                <div class="classifyBox-left">行业：</div>
                <div class="classifyBox-right">
                    <div :class="['classifyItem', firstClassifyIndex === index ? 'classifyItem-active' : '']"
                         v-for="(item, index) in courseClassData"
                         @click="selFirstClass(index)"
                         :key="index">{{ item.name }}</div>
                </div>
            </div>
            <div class="lineBox"><div class="line"></div></div>
            <div class="box-classifyBox">
                <div class="classifyBox-left">课程：</div>
                <div class="classifyBox-right">
                    <div :class="['classifyItem', secondClassifyIndex === index ? 'classifyItem-active' : '']"
                         v-for="(item, index) in courseSecondClassData"
                         @click="selSecondClass(index)"
                         :key="index">{{ item.name }}</div>
                </div>
            </div>
        </div>
        <div class="mainListBox">
            <div class="card"
                 v-for="(item, index) in courseList"
                 :key="index"
                 :style="{'margin-left': mlZero.includes(index) ? '1px' : '12px'}"
                 @click="toCourseDetails(item)">
                <img :src="item.thumbnail" alt="">
                <div class="courseName">{{item.name}}</div>
                <div class="bottomInfo">
                    <div class="time">课时数：{{ item.lessonCnt }}</div>
                    <div class="learnTimes">学习人数：{{ item.learnCnt }}</div>
                </div>
            </div>
        </div>
        <div class="paginationBox">
            <div class="total">共{{ courseListData.length }}课程</div>
            <el-pagination
                @current-change="handleCurrentChange"
                background
                layout="prev, pager, next, jumper"
                :page-size="15"
                :total="courseTotal">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import courseClass from '../../assets/jsons/courseClass.json'
import courseData from '../../assets/jsons/courseData.json'
export default {
    name: "courseCenter",
    data () {
        return {
            firstClassifyIndex: 0,
            secondClassifyIndex: 0,
            courseClassData: courseClass,
            courseSecondClassData: [],
            courseList: [],
            courseListData: [],
            courseData: courseData,
            courseTotal: 0,
            query:{},
            from: '',
            mlZero: [0, 5, 10]
        }
    },
    beforeRouteLeave (to, from, next) {
        if (to.name === 'courseDetails') {
            localStorage.setItem('firstClassifyIndex', this.firstClassifyIndex)
            localStorage.setItem('secondClassifyIndex', this.secondClassifyIndex)
        }
        next()
    },
    beforeRouteEnter (to, from, next) {
        if (from.name !== 'courseDetails') {
            localStorage.removeItem('firstClassifyIndex')
            localStorage.removeItem('secondClassifyIndex')
        }
        next()
    },
    mounted () {
        let f = localStorage.getItem('firstClassifyIndex')
        let s = localStorage.getItem('secondClassifyIndex')
        localStorage.removeItem('firstClassifyIndex')
        localStorage.removeItem('secondClassifyIndex')
        let query = this.$route.query
        let firstIndex = query.firstIndex ? Number(query.firstIndex) : this.firstClassifyIndex
        let secondIndex = query.secondIndex ? Number(query.secondIndex)+1 :  this.secondClassifyIndex
        if (f) {
            firstIndex = Number(f)
            secondIndex = Number(s)
        }
        this.selFirstClass(firstIndex)
        this.selSecondClass(secondIndex)
    },
    methods: {
        selFirstClass (index) {
            this.firstClassifyIndex = index
            this.secondClassifyIndex = 0
            this.courseSecondClassData = [{
                "name": "全部",
                "children": []
            }]
            if (index === 0) {
                this.selAll()
                return
            } else {
                this.courseSecondClassData.push(...this.courseClassData[index].children)
            }
            this.selSecondClass(0)
        },
        selSecondClass (index) {
            if (this.firstClassifyIndex === 0) {
                return
            }
            this.courseListData = []
            this.secondClassifyIndex = index
            let cArr = [],allSecondArr = this.courseSecondClassData[index].children
            if (index === 0) {
                allSecondArr = []
                this.courseSecondClassData.map(item => {
                    allSecondArr.push(...item.children)
                })
            }
            allSecondArr.map(item => {
                cArr.push(item.id)
            })
            this.courseData.map(item => {
                if (cArr.includes(item.id)) {
                    this.courseListData.push(item)
                }
            })
            this.courseTotal = this.courseListData.length
            if (this.courseTotal <= 15) {
                this.courseList = this.courseListData
            } else {
                this.courseList = this.courseListData.slice(0, 15)
            }
        },
        selAll () {
            this.courseListData = this.courseData
            this.courseTotal = this.courseListData.length
            if (this.courseTotal <= 15) {
                this.courseList = this.courseListData
            } else {
                this.courseList = this.courseListData.slice(0, 15)
            }
        },
        toCourseDetails (item) {
            localStorage.setItem('courseDetailsData', JSON.stringify(item))
            this.$router.push({name: 'courseDetails'})
        },
        handleCurrentChange (page) {
            let start = (page - 1) * 15,
                end = (page - 1) * 15 + 15
            this.courseList = this.courseListData.slice(start, end)
        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep {
        .el-pagination.is-background .el-pager li:not(.disabled).active {
            background-color: #EA5A41;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active:hover {
            color: #FFFFFF;
        }
        .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
            background-color: #FFFFFF;
            border: 1px solid #d9d9d9;
            box-sizing: content-box;
        }
        .el-pagination.is-background .el-pager li:hover {
            color: #EA5A41;
        }
        .el-pager li {
            padding: 0;
        }
    }

    .content {
        div {
            transition: all .3s;
        }
    }

    @media screen and (max-width: 1280px) {
        .content {
            width: 100%;
            min-width: 1200px;
            min-height: 100vh;
            background: #FAFAFA;
            box-sizing: border-box;
            overflow: hidden;
            color: #333333;
            padding-bottom: 60px;

            div {
                box-sizing: border-box;
            }

            .tabbarBox {
                width: 1000px;
                //height: 215px;
                margin: 20px auto;

                .titleBox {
                    width: 1000px;
                    height: 35px;
                    background: linear-gradient(135deg, rgba(255, 187, 141, .3), rgba(255, 74, 51, .3));
                    padding-left: 12px;
                    display: flex;
                    align-items: center;

                    .title {
                        height: 19px;
                        line-height: 19px;
                        padding-left: 10px;
                        border-left: 4px solid #EA5A41;
                        font-size: 18px;
                        font-weight: bold;
                        color: #333333;
                    }
                }
                .lineBox {
                    padding: 0 12px;
                    height: 1px;
                    background: #FFFFFF;
                    .line {
                        width: 100%;
                        height: 100%;
                        background: #f3f3f3;
                    }
                }
                .box-classifyBox {
                    background: #FFFFFF;
                    width: 1000px;
                    padding: 12px 12px 24px;
                    display: flex;

                    .classifyBox-left {
                        font-size: 14px;
                        flex-shrink: 0;
                        margin-top: 16px;
                    }

                    .classifyBox-right {

                        .classifyItem {
                            padding: 7px 13px;
                            float: left;
                            margin: 10px 0 0 12px;
                            font-size: 14px;
                            border-radius: 30px;
                            cursor: pointer;
                        }
                        .classifyItem:hover {
                            color: #EA5A41;
                        }
                        .classifyItem-active {
                            background: #EA5A41;
                            color: white;
                        }
                        .classifyItem-active:hover {
                            color: white;
                        }
                    }
                }
            }
            .mainListBox {
                padding-bottom: 10px;
                width: 1000px;
                margin: auto;
                display: flex;
                flex-wrap: wrap;

                .card {
                    flex-shrink: 0;
                    width: 190px;
                    background: #FFFFFF;
                    border: 1px solid #F2F2F2;
                    position: relative;
                    margin-bottom: 20px;
                    margin-left: 10px;

                    img {
                        width: 100%;
                        height: 118px;
                    }
                    .courseName {
                        font-size: 14px;
                        font-weight: bold;
                        margin-top: 12px;
                        max-height: 37px;
                        overflow: hidden;
                        width: 100%;
                        padding: 0 10px 76px;
                    }
                    .bottomInfo {
                        width: 100%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        padding: 10px;
                        display: flex;
                        justify-content: space-between;
                        font-size: 9px;
                        color: #999999;
                    }
                }
            }
            .paginationBox {
                width: 1000px;
                margin: auto;
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.65);
            }
        }
    }

    @media screen and (min-width: 1280px) {
        .content {
            width: 100%;
            min-width: 1200px;
            min-height: 100vh;
            background: #FAFAFA;
            box-sizing: border-box;
            overflow: hidden;
            color: #333333;
            padding-bottom: 60px;

            div {
                box-sizing: border-box;
            }

            .tabbarBox {
                width: 1200px;
                //height: 215px;
                margin: 20px auto;

                .titleBox {
                    width: 1200px;
                    height: 35px;
                    background: linear-gradient(135deg, rgba(255, 187, 141, .3), rgba(255, 74, 51, .3));
                    padding-left: 12px;
                    display: flex;
                    align-items: center;

                    .title {
                        height: 19px;
                        line-height: 19px;
                        padding-left: 10px;
                        border-left: 4px solid #EA5A41;
                        font-size: 18px;
                        font-weight: bold;
                        color: #333333;
                    }
                }
                .lineBox {
                    padding: 0 12px;
                    height: 1px;
                    background: #FFFFFF;
                    .line {
                        width: 100%;
                        height: 100%;
                        background: #f3f3f3;
                    }
                }
                .box-classifyBox {
                    background: #FFFFFF;
                    width: 1200px;
                    padding: 12px 12px 24px;
                    display: flex;

                    .classifyBox-left {
                        font-size: 14px;
                        flex-shrink: 0;
                        margin-top: 16px;
                    }

                    .classifyBox-right {

                        .classifyItem {
                            padding: 7px 13px;
                            float: left;
                            margin: 10px 0 0 12px;
                            font-size: 14px;
                            border-radius: 30px;
                            cursor: pointer;
                        }
                        .classifyItem:hover {
                            color: #EA5A41;
                        }
                        .classifyItem-active {
                            background: #EA5A41;
                            color: white;
                        }
                        .classifyItem-active:hover {
                            color: white;
                        }
                    }
                }
            }
            .mainListBox {
                padding-bottom: 10px;
                width: 1200px;
                margin: auto;
                display: flex;
                flex-wrap: wrap;

                .card {
                    flex-shrink: 0;
                    width: 230px;
                    background: #FFFFFF;
                    border: 1px solid #F2F2F2;
                    position: relative;
                    margin-bottom: 20px;

                    img {
                        width: 100%;
                        height: 131px;
                    }
                    .courseName {
                        font-size: 14px;
                        font-weight: bold;
                        margin-top: 12px;
                        max-height: 37px;
                        overflow: hidden;
                        width: 100%;
                        padding: 0 10px 76px;
                    }
                    .bottomInfo {
                        width: 100%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        padding: 10px;
                        display: flex;
                        justify-content: space-between;
                        font-size: 11px;
                        color: #999999;
                    }
                }
            }
            .paginationBox {
                width: 1200px;
                margin: auto;
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.65);
            }
        }
    }
</style>